import React, { useState } from "react";
import { Menu, Layout } from "antd";
import { useUserStore } from "../../../store/userStore";
const { Sider } = Layout;

function filterMenuItemsByRole(menuItems, role) {
  function filterRecursive(items) {
    return items.filter((menuItem) => {
      if (menuItem.roles && menuItem.roles.includes(role)) {
        return true;
      }

      if (menuItem.children) {
        menuItem.children = filterRecursive(menuItem.children);
        return menuItem.children.length > 0;
      }

      return false;
    });
  }

  return filterRecursive(menuItems);
}

export const SideBar = ({ onClick, menuItems }) => {
  const [collapsed, setCollapsed] = useState(true);

  const userData = useUserStore((state) => state.user);

  const permittedMenuItems = filterMenuItemsByRole(menuItems, userData.role);

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      width={200}
      collapsedWidth="3.5rem"
      className="site-layout-background"
    >
      <Menu
        mode="inline"
        style={{
          height: "100%",
          borderRight: 0,
        }}
        onClick={onClick}
        items={permittedMenuItems}
      />
    </Sider>
  );
};
