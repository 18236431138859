import React from "react";
import { supabase } from "../../../supabase/client";
import { useNavigate } from "react-router-dom";
import { Layout, Menu, Image, Dropdown } from "antd";
import { UserOutlined } from "@ant-design/icons";
import SaiLogo from "../../../assets/logo/sai.png";
import "./style.css";
import { useUserStore } from "../../../store/userStore";
const { Header } = Layout;

export const NavBar = ({ onClick }) => {
  const menu = () => (
    <Menu
      mode="horizontal"
      items={[
        {
          label: "Sign Out",
          key: "sign-out",
          onClick: async () => {
            await supabase.auth.signOut();
            useUserStore.setState({ user: {} });
            navigate("/signin");
          },
        },
      ]}
    />
  );

  const navigate = useNavigate();
  return (
    <Header
      className="header"
      style={{
        display: "flex",
        gap: 10,
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 2rem",
      }}
    >
      <Image
        height={"100%"}
        style={{ margin: "auto 0", padding: 10, cursor: "pointer" }}
        src={SaiLogo}
        preview={false}
        onClick={onClick}
      />
      <Dropdown.Button
        className="dropdown-btn"
        overlay={menu}
        trigger={["click", "hover"]}
        icon={
          <UserOutlined
            style={{
              fontSize: 20,
              color: "white",
            }}
          />
        }
      />
    </Header>
  );
};
