import { toast } from "react-toastify";

export const handleError = (error) => {
  if (error) {
    toast.error(error.message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
    });
  }
};

export const handleSuccess = (message) => {
  if (message) {
    toast.success(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
    });
  }
};
