import { lazily } from "react-lazily";
import { AdminLayout } from "./layouts/auth";

export const ROUTES = [
  {
    key: "admin",
    path: "",
    component: () => {
      return AdminLayout;
    },
    roles: [0, 1, 2, 3],
    nestedRoute: [
      {
        path: "/home",
        component: () => {
          const { AdminHome } = lazily(() => import("./components/admin/home"));
          return AdminHome;
        },
        roles: [0, 1, 2, 3],
      },
      {
        path: "/customer/add",
        component: () => {
          const { AddCustomer } = lazily(() =>
            import("./components/admin/add_customer/index")
          );
          return AddCustomer;
        },
        roles: [3],
      },
      {
        path: "/customer/manage",
        component: () => {
          const { ViewCustomers } = lazily(() =>
            import("./components/admin/view_customers/index")
          );
          return ViewCustomers;
        },
        roles: [3],
      },
      {
        path: "/employee/add",
        component: () => {
          const { AddEmployee } = lazily(() =>
            import("./components/admin/add_employee/index")
          );
          return AddEmployee;
        },
        roles: [3],
      },
      {
        path: "/employee/manage",
        component: () => {
          const { ViewEmployees } = lazily(() =>
            import("./components/admin/view_employee/index")
          );
          return ViewEmployees;
        },
        roles: [3],
      },
      {
        path: "/employee/factor",
        component: () => {
          const { EmployeeFactors } = lazily(() =>
            import("./components/admin/employee_factor/index")
          );
          return EmployeeFactors;
        },
        roles: [3],
      },
      {
        path: "/machine/add",
        component: () => {
          const { AddMachine } = lazily(() =>
            import("./components/admin/create_machine/index")
          );
          return AddMachine;
        },
        roles: [3],
      },
      {
        path: "/machine/manage",
        component: () => {
          const { ManageMachine } = lazily(() =>
            import("./components/admin/manage_machine/index")
          );
          return ManageMachine;
        },
        roles: [3],
      },
      {
        path: "/machine/maintenance",
        component: () => {
          const { MachineMaintainance } = lazily(() =>
            import("./components/admin/machine_maintainance/index")
          );
          return MachineMaintainance;
        },
        roles: [1, 3],
      },
      {
        path: "/workorder/create",
        component: () => {
          const { CreateWorkOrder } = lazily(() =>
            import("./components/programmer/create_workorder/index")
          );
          return CreateWorkOrder;
        },
        roles: [1, 2, 3],
      },
      {
        path: "/workorder/manage",
        component: () => {
          const { ManageWorkOrder } = lazily(() =>
            import("./components/programmer/manage_workorder/index")
          );
          return ManageWorkOrder;
        },
        roles: [1, 2, 3],
      },
      {
        path: "/workorder/superdrill",
        component: () => {
          const { SuperDrill } = lazily(() =>
            import("./components/programmer/superdrill")
          );
          return SuperDrill;
        },
        roles: [1, 2, 3],
      },
      {
        path: "/job/schedule",
        component: () => {
          const { ScheduleJobs } = lazily(() =>
            import("./components/programmer/schedule_jobs")
          );
          return ScheduleJobs;
        },
        roles: [2, 3],
      },
      {
        path: "/job/initiate",
        component: () => {
          const { InitiateJob } = lazily(() =>
            import("./components/operator/initiate_job")
          );
          return InitiateJob;
        },
        roles: [1, 3],
      },
      {
        path: "/billing/approval",
        component: () => {
          const { ApproveInvoice } = lazily(() =>
            import("./components/admin/approve_invoice")
          );
          return ApproveInvoice;
        },
        roles: [3],
      },
      {
        path: "/billing/bill",
        component: () => {
          const { BillingScreen } = lazily(() =>
            import("./components/accountant/billing/index")
          );
          return BillingScreen;
        },
        roles: [0, 3],
      },
      {
        path: "/insights/operator",
        component: () => {
          const { OperatorPerformance } = lazily(() =>
            import("./components/admin/reports/operator_performance")
          );
          return OperatorPerformance;
        },
        roles: [3],
      },
      {
        path: "/insights/programmer",
        component: () => {
          const { ProgrammerPerformance } = lazily(() =>
            import("./components/admin/reports/programmer_performance")
          );
          return ProgrammerPerformance;
        },
        roles: [3],
      },
      {
        path: "/insights/machine",
        component: () => {
          const { MachinePerformance } = lazily(() =>
            import("./components/admin/reports/machine_performance")
          );
          return MachinePerformance;
        },
        roles: [3],
      },
      {
        path: "/insights/customer",
        component: () => {
          const { CustomerReport } = lazily(() =>
            import("./components/admin/reports/customer_report")
          );
          return CustomerReport;
        },
        roles: [3],
      },
      {
        path: "/insights/attendance",
        component: () => {
          const { Attendance } = lazily(() =>
            import("./components/admin/reports/atendance")
          );
          return Attendance;
        },
        roles: [3],
      },
      {
        path: "/insights/salary",
        component: () => {
          const { SalaryReport } = lazily(() =>
            import("./components/admin/reports/salary")
          );
          return SalaryReport;
        },
        roles: [3],
      },
    ],
  },
];
