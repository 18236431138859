import React, { useEffect } from "react";
import { useState } from "react";
import { Input, Button, Card, Image } from "antd";
import "./style.css";
import { supabase } from "../../../supabase/client";
import { Content, Header } from "antd/lib/layout/layout";
import { Layout } from "antd";
import { useNavigate } from "react-router-dom";
import { handleError } from "../../../helper/auth";
import SaiLogo from "../../../assets/logo/sai.png";
import SaiBlackLogo from "../../../assets/logo/sai_black.png";
import { useUserStore } from "../../../store/userStore";

export default function SignIn() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const userData = useUserStore((state) => state.user || {});

  useEffect(() => {
    if (userData.role >= 0) {
      navigate("/home");
    }
  }, [userData]);

  const onSubmit = async () => {
    let { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    handleError(error);
    const AuthData = data?.user;
    if (AuthData?.id) {
      navigate("/home");
    }

    setEmail("");
    setPassword("");
  };

  return (
    <Layout>
      <Header
        className="header"
        style={{
          display: "flex",
          gap: 10,
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 2rem",
        }}
      >
        <Image
          height={"100%"}
          style={{ margin: " auto 0", padding: 10 }}
          src={SaiLogo}
          preview={false}
        />
      </Header>
      <Content
        style={{
          minHeight: "calc(100vh - 64px)",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div className="signinContainer">
          <Card className="card">
            <Image width={100} src={SaiBlackLogo} preview={false} />
            <Input
              size="large"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              type={"email"}
              placeholder={"Email"}
            />
            <Input.Password
              size="large"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type={"password"}
              placeholder={"Password"}
            />
            <Button size="large" onClick={onSubmit} type="primary">
              Login
            </Button>
          </Card>
        </div>
      </Content>
    </Layout>
  );
}
