import React from "react";
import { DisconnectOutlined } from "@ant-design/icons";
import { Button, Skeleton } from "antd";
import { useNavigate } from "react-router-dom";
import { useUserStore } from "../../../store/userStore";

export const FallBack = () => {
  const navigate = useNavigate();
  const loading = useUserStore((state) => state.loading);

  if (loading) {
    return <Skeleton active={true} />;
  }
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: "2rem",
      }}
    >
      <DisconnectOutlined style={{ fontSize: 50, color: "#207398" }} />
      <h1>Invalid Route</h1>
      <Button onClick={() => navigate("/home")}>Return Home</Button>
    </div>
  );
};
