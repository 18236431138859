import { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import "antd/dist/antd.min.css";
import SignIn from "./components/common/signin";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { supabase } from "./supabase/client";
import { handleError } from "./helper/auth";
import { useUserStore } from "./store/userStore";
import { Skeleton } from "antd";
import { ROUTES } from "./Routes";
import { FallBack } from "./components/common/fallback";

function App() {
  const [session, setSession] = useState(null);

  const userData = useUserStore((state) => state.user || {});

  useEffect(() => {
    useUserStore.setState({ loading: true });
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });
    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
  }, []);

  useEffect(() => {
    async function getUser(id) {
      const { data, error } = await supabase
        .from("user_data")
        .select()
        .eq("id", id);
      handleError(error);
      if (data) {
        useUserStore.setState({ ...useUserStore.getState(), user: data[0] });
        const { count } = await supabase
          .from("user_data")
          .select("*", { count: "exact", head: true });
        if (count) {
          useUserStore.setState({
            ...useUserStore.getState(),
            total_user_count: count,
          });
        }
      }
      useUserStore.setState({ loading: false });
    }
    if (session?.user?.id) {
      getUser(session?.user?.id);
    }
  }, [session]);

  const channel = supabase.channel("online-users", {
    config: {
      presence: {
        key: session?.user?.id,
      },
    },
  });

  channel.on("presence", { event: "sync" }, () => {
    useUserStore.setState({
      ...useUserStore.getState(),
      active_users: channel.presenceState()[session?.user?.id],
    });
  });

  channel.subscribe(async (status) => {
    if (status === "SUBSCRIBED") {
      await channel.track({
        online_at: new Date().toISOString(),
      });
    }
  });

  return (
    <BrowserRouter>
      <ToastContainer />
      <Suspense fallback={<Skeleton active={true} />}>
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/signin" element={<SignIn />} />
          {ROUTES.map((route) => {
            const Component = route.component();
            return (
              route.roles.includes(userData.role) && (
                <Route
                  path={route.path}
                  key={route.key}
                  element={<Component />}
                >
                  {route.nestedRoute
                    ? route.nestedRoute.map((subRoute) => {
                        const Element = subRoute.component();
                        return (
                          subRoute.roles.includes(userData.role) && (
                            <Route
                              path={subRoute.path}
                              key={subRoute.path}
                              element={<Element />}
                            />
                          )
                        );
                      })
                    : null}
                </Route>
              )
            );
          })}
          <Route path="*" exact element={<FallBack />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
