import {
  CheckCircleOutlined,
  ControlOutlined,
  DollarCircleOutlined,
  HomeOutlined,
  LineChartOutlined,
  PlaySquareOutlined,
  ScheduleOutlined,
  TeamOutlined,
  ToolOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";

export const LEFTNAV_ITEMS = [
  {
    label: "Home",
    key: "home",
    icon: <HomeOutlined />,
    roles: [0, 1, 2, 3],
  },
  {
    label: "Customer",
    key: "customer",
    icon: <UserSwitchOutlined />,
    roles: [3],
    children: [
      {
        label: "Add Customer",
        key: "customer/add",
        roles: [3],
      },
      {
        label: "Manage Customers",
        key: "customer/manage",
        roles: [3],
      },
    ],
  },
  {
    label: "Employee",
    key: "employee",
    icon: <TeamOutlined />,
    roles: [3],
    children: [
      {
        label: "Add Employee",
        key: "employee/add",
        roles: [3],
      },
      {
        label: "Manage Employees",
        key: "employee/manage",
        roles: [3],
      },
      {
        label: "Employee factors",
        key: "employee/factor",
        roles: [3],
      },
    ],
  },
  {
    label: "Machine",
    key: "machine",
    icon: <ControlOutlined />,
    roles: [1, 3],
    children: [
      {
        label: "Add Machine",
        key: "machine/add",
        roles: [3],
      },
      {
        label: "Manage Machines",
        key: "machine/manage",
        roles: [3],
      },
      { label: "Maintenance", key: "machine/maintenance", roles: [1, 3] },
    ],
  },
  {
    label: "Work Order",
    key: "workorder",
    icon: <ToolOutlined />,
    roles: [1, 2, 3],
    children: [
      {
        label: "Create WorkOrder",
        key: "workorder/create",
        roles: [1, 2, 3],
      },
      {
        label: "Manage WorkOrder",
        key: "workorder/manage",
        roles: [1, 2, 3],
      },
      {
        label: "SuperDrill",
        key: "workorder/superdrill",
        roles: [1, 2, 3],
      },
    ],
  },
  {
    label: "Schedule Jobs",
    key: "job/schedule",
    icon: <ScheduleOutlined />,
    roles: [2, 3],
  },
  {
    label: "Initiate Job",
    key: "job/initiate",
    icon: <PlaySquareOutlined />,
    roles: [1, 3],
  },
  {
    label: "Billing approval",
    key: "billing/approval",
    icon: <CheckCircleOutlined />,
    roles: [3],
  },
  {
    label: "Billing",
    key: "billing/bill",
    icon: <DollarCircleOutlined />,
    roles: [0, 3],
  },
  {
    label: "Insights",
    key: "insights",
    icon: <LineChartOutlined />,
    roles: [3],
    children: [
      {
        label: "Operator Performance",
        key: "insights/operator",
        roles: [3],
      },
      {
        label: "Programmer Performance",
        key: "insights/programmer",
        roles: [3],
      },
      {
        label: "Machine Performance",
        key: "insights/machine",
        roles: [3],
      },
      {
        label: "Customer Report",
        key: "insights/customer",
        roles: [3],
      },
      {
        label: "Attendance tracker",
        key: "insights/attendance",
        roles: [3],
      },
      {
        label: "Salary report",
        key: "insights/salary",
        roles: [3],
      },
    ],
  },
];
