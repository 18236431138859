import React, { Suspense } from "react";

import { Layout, Skeleton } from "antd";

import { NavBar } from "../../components/common/navbar";
import { SideBar } from "../../components/common/sidebar";
import { Outlet, useNavigate } from "react-router-dom";
import { LEFTNAV_ITEMS } from "../../LeftNavItems";

const { Content } = Layout;

export const AdminLayout = () => {
  const navigate = useNavigate();
  return (
    <Layout>
      <NavBar
        onClick={() => {
          navigate("/home");
        }}
      />
      <Layout>
        <SideBar
          onClick={(e) => {
            navigate(e.key);
          }}
          menuItems={LEFTNAV_ITEMS}
        />
        <Layout>
          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              margin: 0,
              height: "calc(100vh - 64px)",
              overflow:"auto"
            }}
          >
            <Suspense fallback={<Skeleton active={true} />}>
              <Outlet />
            </Suspense>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};
